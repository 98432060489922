import * as React from "react";
import { Link } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Keyboard } from "swiper";
import PropTypes from 'prop-types'

// swiper bundle styles
import "swiper/css/bundle";
// swiper core styles
import "swiper/css";
// modules styles
import "swiper/css/navigation";
import "swiper/css/pagination";


const CapabilitiesSwiper = ({ capabilities }) => {

  return (
    <div className="capabilities-swiper__wrap">
      <Swiper
      speed={800}
      className="capabilities-swiper" 
      modules={[Navigation, Keyboard]}
      slidesPerView={'auto'}
      keyboard={true}
      navigation={ {clickable: true}  }
      spaceBetween={40}
      observer={true}
      observeParents={true}
      breakpoints={{
        744: {
          spaceBetween: 100,
        },
        1024: {
          spaceBetween: 120
        },
        1200: {
          spaceBetween: 140
        } 
      }}
      >
        {capabilities.map((capability, i) => (
          <SwiperSlide
            key={capability.node.id} 
            className="capability__slide"  
          >
            <Link
              className="capability__link"
              to={`/agency/capabilities/${capability.node.name
                  .toLowerCase()
                  .replaceAll(" ", "-")}`}
            >
              <h3 className="capability__name">{capability.node.name}</h3>
            
            </Link>
          </SwiperSlide>
        ))}
      
      </Swiper>
    </div>
  );
};

CapabilitiesSwiper.propTypes = {
  capabilities: PropTypes.array.isRequired
}

export default CapabilitiesSwiper;
