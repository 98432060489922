import { graphql } from "gatsby";
import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DetailIntro from "../components/detailIntro"
import RelatedWork from "../components/relatedWork"
import Capabilities from "../components/capabilities"



const CapabilityDetail = ({ location, data }) => {

  const capability = data.allSanityCapability.edges[0].node;
  const relatedWork = data.allSanityWorkItem.edges;

  return (
    <Layout scheme="dark" location={location} pageCategory="agency">
      <Seo title={ `MullenLowe U.S. — ${capability.name}` } />
      <DetailIntro title={capability.name} copy={capability._rawDescription} />
      {relatedWork.length > 0 && (
        <RelatedWork 
          listItems={relatedWork} 
          title="Related Case Studies" 
          scheme="dark"
          borderBottom
          capabilitiesPage
        />
      )}

      <Capabilities capabilities={data.otherCapabilities.edges} otherCapabilities />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!, $name: String!) {
    allSanityCapability(filter: { id: { eq: $id } }) {
      edges {
        node {
          name
          _rawDescription
        }
      }
    }
    otherCapabilities: allSanityCapability(filter: {name: {ne: $name}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          name
          id
          order
          _rawDescription
        }
      }
    } 
    allSanityWorkItem(filter: {capabilities: {elemMatch: {name: {eq: $name}}}, workType: {nin: "work-ml"}}, limit: 3) {
      edges {
        node {
          id
          capabilities {
            name
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`

export default CapabilityDetail