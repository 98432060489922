import * as React from 'react'
import { Link } from "gatsby"
import classNames from 'classnames'
import PropTypes from 'prop-types'

const RelatedWork = ({ listItems, title, scheme, borderBottom, capabilitiesPage }) => {
  return (
    <section
      aria-label={title}
      className={classNames('container related', scheme, {
        'related--border-bottom': borderBottom,
        'related--capabilities-pg': capabilitiesPage
      })} 
    >
      <h2 className="descriptor">{title}</h2>
      <ul className='related__list'>
        {listItems.map(item => (
          <li className='related__item' key={item.node.id}>
            <Link className="related__item-link" to ={`/work/${item.node.slug.current}`}>
              {item.node.title}
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

RelatedWork.propTypes = {
  listItems: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  scheme: PropTypes.string,
  borderBottom: PropTypes.bool,
  capabilitiesPage: PropTypes.bool
}

export default RelatedWork