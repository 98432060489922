import * as React from 'react'
import CapabilitiesSwiper from './capbilitiesSwiper'
import PropTypes from 'prop-types'

const Capabilities = ({ capabilities, otherCapabilities }) => {
  return (
    <section className={`capabilities ${otherCapabilities ? 'capabilities--other' : ''}`} aria-labelledby="capabilities">
      <h2 id="capabilities" className="container descriptor">Capabilities</h2>
      <CapabilitiesSwiper capabilities={capabilities} />
    </section>
  )
}

Capabilities.propTypes = {
  capabilities: PropTypes.array.isRequired,
  otherCapabilities: PropTypes.bool
}

export default Capabilities