import { PortableText } from '@portabletext/react'
import * as React from 'react'
import PropTypes from 'prop-types'

const DetailIntro = ({ title, copy }) => {
  return (
    <section aria-label="intro" className="container header-spacer">
      <div className="detail-intro">
        <h1 className="detail-intro__title">{title}.</h1>
        <PortableText value={copy} />
      </div>
    </section>
  )
}

DetailIntro.propTypes = {
  title: PropTypes.string,
  copy: PropTypes.array
}

export default DetailIntro